import React from "react";
import './Footer.css';

function Footer(){
    return(
        <main className="footer">
            Footer
        </main>
    );
}

export default Footer;